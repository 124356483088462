import { ErrorMessage, Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal } from 'reactstrap';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import {
  ClassroomCombineTypes,
  CourseFormInitialValues,
  ExamPeriodCourseTermLectureLocations,
  ExamTypes,
  ExamEnvironments,
  ExamOverlapTypes
} from '../../store/constants/course-const';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import { CombineExamFormValidation } from './validations/combine-exams-form-val';
import Translator from '../../services/translate-factory';
import { UncontrolledTooltip } from 'reactstrap';
const T = Translator.create();

const equal = require('deep-equal');

const Logger = Log.create('CombineExamsModal');

class CombineExamsModal extends Component<Types.ICombineModalProps, Types.ICombineExamsDataModel> {
  state: Types.ICombineExamsDataModel = {
    // dataModel: Object.assign({}, CombineExamsDataModel),
    term_id: -1,
    combined_courses: [],
    formValues: {
      course_code: '',
      conjugate_course_code: '',
      name: '',
      student_count: -1,
      lecture_staff: [],
      lecture_staff_ids: [],
      assistant_staff: [],
      assistant_staff_ids: [],
      programs: [],
      program_ids: [],
      campus: undefined,
      campus_id: 0,
      faculty: undefined,
      faculty_id: 0,
      exam_duration: undefined,
      class: undefined,
      section: '',
      exam_session: undefined,
      invigilator_gap_before_exam: 0,
      invigilator_gap_after_exam: 0,
      exam_environment_select: undefined,
      exam_environment: 0,
      exam_overlap_type_select: undefined,
      exam_overlap_type: 0,
      exam_type_select: undefined,
      exam_type: 0,
      classroom_combine_status: -1,
      description: ''
    }
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/);
    let id = regexObject ? parseInt(regexObject[0], 10) : -1;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.term_id = id;
    this.searchCourses();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  searchCourses() {
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  onUpdateListFromModal = (refresh: boolean = false) => {
    if (this.props.onUpdateList) {
      this.props.onUpdateList(refresh);
    }
  };

  onFormSave = (model: Types.ICourseItem, FormActions: FormikActions<Types.ICourseItem>) => {
    let combinedCoursesArray: any = this.props.courseList && this.props.courseList.map(
      (obj: any) => obj.item.course_id
    );

    let instructor_ids = model.instructors ? model.instructors.map((a) => a.value) : [];
    let program_ids = model.programs ? model.programs.map((a) => a.value) : [];
    let campus_ids = model.campuses ? model.campuses.map((a) => a.value) : [];
    let faculty_ids = model.faculties ? model.faculties.map((a) => a.value) : [];
    let classes = model.class_ids ? model.class_ids : [];
    let sections = model.sections ? model.sections : [];
    let student_counts = model.student_counts ? model.student_counts : [];

    let course_programs: any;
    course_programs = faculty_ids.map((faculty_id: any, index: any) => (
      {
        faculty_id: faculty_id,
        campus_id: campus_ids[index],
        program_id: program_ids[index],
        instructor_id: instructor_ids[index],
        class: classes[index],
        section: sections[index],
        student_count: student_counts[index]
      }
    ));

    this.state.formValues.course_programs = course_programs;
    this.state.combined_courses = combinedCoursesArray;
    this.state.formValues.course_code = model.course_code;
    this.state.formValues.name = model.name;
    this.state.formValues.conjugate_course_code = model.conjugate_course_code;
    this.state.formValues.exam_duration = model.exam_duration ? model.exam_duration : 1;
    this.state.formValues.faculty_id = model.faculty_id ? model.faculty_id : -1;
    this.state.formValues.campus_id = model.campus_id ? model.campus_id : -1;
    this.state.formValues.program_ids = model.programs ? model.programs.map((a) => a.value) : [];
    this.state.formValues.class = model.class;
    this.state.formValues.section = model.section;
    this.state.formValues.lecture_staff_ids = model.lecture_staff ? model.lecture_staff.map((a) => a.value) : [];
    this.state.formValues.assistant_staff_ids = model.assistant_staff ? model.assistant_staff.map((a) => a.value) : [];
    this.state.formValues.exam_environment = model.exam_environment ? model.exam_environment : -1;
    this.state.formValues.exam_overlap_type = model.exam_overlap_type ? model.exam_overlap_type : -1;
    this.state.formValues.exam_type = model.exam_type ? model.exam_type : -1;
    this.state.formValues.classroom_combine_status = model.classroom_combine_status;
    this.state.formValues.exam_session = model.exam_session;
    this.state.formValues.invigilator_gap_before_exam = model.invigilator_gap_before_exam;
    this.state.formValues.invigilator_gap_after_exam = model.invigilator_gap_after_exam;
    this.state.formValues.description = model.description;

    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_exams_will_be_combined'),
        body: T.t('gen_exams_combine_question'),
        name: 'examPeriod_courses_delete',
        icon: 'warning',
        iconColor: 'red',
        confirm: T.t('gen_yes'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
            if (result && result.code) {
              let errors: any = {};
              if (result.details) {
                const validations: Array<Types.IValidationResponse> = result.details;
                validations.forEach((m: Types.IValidationResponse) => {
                  errors[m.field] = m.message[0];
                });
              }
              FormActions.setErrors(errors);
            }
            if (status === 200 || status === 201) {
              this.setCloseModal();
              this.onUpdateListFromModal();
            }
          };

          this.state.course_id = this.props.courseObject ? this.props.courseObject.course_id : -1;

          if (this.props.showCoursesOnly) {
            this.props.dispatch(
              Actions.ApiRequest(
                Constants.exam_period.EXAM_PERIOD_UPDATE_COMBINE_EXAMS,
                this.state,
                'ep-course-list',
                resultCallback
              )
            );
          } else {
            this.props.dispatch(
              Actions.ApiRequest(
                Constants.exam_period.EXAM_PERIOD_CREATE_COMBINE_EXAMS,
                this.state,
                'ep-course-list',
                resultCallback
              )
            );
          }
        }
      })
    );
    FormActions.setSubmitting(false);
  };

  static getDerivedStateFromProps(props: Types.ICombineModalProps, state: Types.ICoursePageState) {
    let hasNewState: boolean = false;

    if (state.combineExamsModalIsOpen && !props.modalIsOpen) {
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  getCombinedExamsFieldsIfEqual = (): Types.ICombineExamsFormFields => {
    let fields: Types.ICombineExamsFormFields = Object.assign({}, this.state.formValues);

    if (this.props.courseList && this.props.courseList.length) {
      const faculty_ids_selected = this.props.courseList.map((course: any) => course.item.courseInfo.faculty_ids);

      let course_codes: Array<any> = [];
      let names: Array<any> = [];
      let descriptions: Array<any> = [];
      faculty_ids_selected.forEach((facultyIds: any, index_ids: any) => {
        facultyIds.forEach((facultyId: any, index_id: any) => {
          course_codes.push(this.props.courseList && this.props.courseList[index_ids].item.courseInfo.course_code.includes(';') ?
            this.props.courseList && this.props.courseList[index_ids].item.courseInfo.course_code.split(';')[index_id] :
            this.props.courseList && this.props.courseList[index_ids].item.courseInfo.course_code);

          names.push(this.props.courseList && this.props.courseList[index_ids].item.courseInfo.name.includes(';') ?
            this.props.courseList && this.props.courseList[index_ids].item.courseInfo.name.split(';')[index_id] :
            this.props.courseList && this.props.courseList[index_ids].item.courseInfo.name);

          if (this.props.courseList && this.props.courseList[index_ids].item.courseInfo.description != undefined) {
            descriptions.push(
              this.props.courseList && this.props.courseList[index_ids].item.courseInfo.description
            );
          }
        });
      });
      const course_code = course_codes.join(';');
      const name = names.join(';');
      const description = GT.isAllEqual(descriptions) ? descriptions[0] : GT.DistinctTextArray(descriptions).join(';');

      let conjugate_course_codes = this.props.courseList.map((course: any) => course.item.courseInfo.conjugate_course_code);
      for (let i = conjugate_course_codes.length; i >= 0; i--) {
        if (conjugate_course_codes[i] == undefined || conjugate_course_codes[i] == '') {
          conjugate_course_codes.splice(i, 1);
        }
      }
      let conjugate_course_code = !conjugate_course_codes.includes(undefined) || !conjugate_course_codes.includes('') || conjugate_course_codes.length != 1 ? conjugate_course_codes.join(';') : '';

      const _classes = this.props.courseList.map((course: any) => course.item.courseInfo.classes);
      const classes = _classes.flat();
      const class_ids = classes.map((staff) => staff.value);

      // const classes = this.props.courseList.map((i: any) => i.item.courseInfo.class);
      // let class_value;
      // if (!this.props.coursesAreCombined) {
      //   class_value = ''
      // } else {
      //   if (classes != null && classes != undefined && classes.length > 1) {
      //     class_value = classes.join(';');
      //   }
      // }

      const _student_count_total = this.props.courseList.map((course: any) => course.item.courseInfo.student_counts);
      let studentCountsMerged: number[] = [];
      _student_count_total.forEach(element => {
        studentCountsMerged = studentCountsMerged.concat(element);
      });
      const student_count_total = studentCountsMerged.reduce((a, b) => a + b, 0);

      const _instructors = this.props.courseList.map((course: any) => course.item.courseInfo.instructors);
      const instructors = _instructors.flat();
      const instructor_ids = instructors.map((staff) => staff.value);

      // const _lecture_staff = this.props.courseList.map((course: any) => course.item.courseInfo.instructors);
      // const flattedStaff = _lecture_staff.flat();
      // const lecture_staff = flattedStaff.filter((v, i, a) => a.findIndex((t) => t.value === v.value) === i);
      // const lecture_staff_ids = lecture_staff.map((staff) => staff.value);

      let hasDifferentValues = (arr: any) =>
        !arr.every((item: any, i: any, ref: any) =>
          JSON.stringify([...ref].shift()).includes(JSON.stringify(item)));

      let campus_id;
      let campus;
      let courseLocationNew = this.props.courseList.map((i: any) => i.item.courseInfo.campus_ids)
      if (hasDifferentValues(courseLocationNew)) {
        campus_id = -1;
        campus = {
          label: T.t("gen_multi_campus"),
          value: -1
        };
      } else {
        campus_id = this.props.courseList[0].item.courseInfo.campus_ids[0];
        campus = {
          label: this.props.courseList[0].item.courseInfo.campuses[0].label,
          value: this.props.courseList[0].item.courseInfo.campuses[0].value
        };
      }

      let exam_environment;
      let exam_environment_select;
      let examEnvironmentNew = this.props.courseList.map((i: any) => i.item.courseInfo.exam_environment)
      if (hasDifferentValues(examEnvironmentNew)) {
        exam_environment = null;
        exam_environment_select = undefined;
      } else {
        exam_environment = this.props.courseList[0].item.courseInfo.exam_environment;
        exam_environment_select = ExamEnvironments(T)[examEnvironmentNew[0] - 1];
      }

      let exam_overlap_type;
      let exam_overlap_type_select;
      let examOverlapTypeNew = this.props.courseList.map((i: any) => i.item.courseInfo.exam_overlap_type)
      if (hasDifferentValues(examOverlapTypeNew)) {
        exam_overlap_type = null;
        exam_overlap_type_select = undefined;
      } else {
        exam_overlap_type = this.props.courseList[0].item.courseInfo.exam_overlap_type;
        exam_overlap_type_select = ExamOverlapTypes(T)[examOverlapTypeNew[0] - 1];
      }

      let exam_type;
      let exam_type_select;
      let examTypeNew = this.props.courseList.map((i: any) => i.item.courseInfo.exam_type)
      if (hasDifferentValues(examTypeNew)) {
        exam_type = null;
        exam_type_select = undefined;
      } else {
        exam_type = this.props.courseList[0].item.courseInfo.exam_type;
        exam_type_select = ExamTypes(T)[examTypeNew[0] - 1];
      }

      // let faculty_id;
      // let faculty;
      // let facultyNew = this.props.courseList.map((i: any) => i.item.courseInfo.faculty_id)
      // if (hasDifferentValues(facultyNew)) {
      //   faculty_id = null;
      //   faculty = undefined;
      // } else {
      //   faculty_id = this.props.courseList[0].item.courseInfo.faculty_id;
      //   faculty = {
      //     label: this.props.courseList[0].item.courseInfo.faculty_name,
      //     value: this.props.courseList[0].item.courseInfo.faculty_id
      //   };
      // }

      const _faculties = this.props.courseList.map((course: any) => course.item.courseInfo.faculties);
      const faculties = _faculties.flat();
      const faculty_ids = faculties.map((faculty: any) => faculty && faculty.value);

      const _campuses = this.props.courseList.map((course: any) => course.item.courseInfo.campuses);
      const campuses = _campuses.flat();
      const campus_ids = campuses.map((campus: any) => campus && campus.value);

      // const _programs = this.props.courseList.map((course: any) => course.item.courseInfo.programs);
      // const flattedPrograms = _programs.flat();
      // // Removes dublicate objects with same 'value' key in an array. https://stackoverflow.com/a/56757215
      // const programs = flattedPrograms.filter((v, i, a) => a.findIndex((t) => t.value === v.value) === i);
      // const program_ids = programs.map((program: any) => program && program.value);

      const _programs = this.props.courseList.map((course: any) => course.item.courseInfo.programs);
      const programs = _programs.flat();
      const program_ids = programs.map((program: any) => program && program.value);

      // const classes = this.props.courseList.map((course: any) => course.item.courseInfo.class);
      // let grade;
      // if(classes!=null && classes!=undefined && classes.length > 1){
      //   grade = classes.join(';');
      // }

      const _sections = this.props.courseList.map((course: any) => course.item.courseInfo.sections);
      const sections = _sections.flat();

      // const _sections = this.props.courseList.map((course: any) => course.item.courseInfo.section);
      // const section = "";

      const _student_counts = this.props.courseList.map((course: any) => course.item.courseInfo.student_counts);
      const student_counts = _student_counts.flat();

      const _assistant_staff = this.props.courseList.map((course: any) => course.item.courseInfo.assistant_staff);
      const flattedAssistantStaff = _assistant_staff[0] != undefined ? _assistant_staff.flat() : [];
      const assistant_staff = flattedAssistantStaff[0] != undefined ? flattedAssistantStaff.filter((v, i, a) => a.findIndex((t) => t.value === v.value) === i) : [];
      const assistant_staff_ids = assistant_staff.map((staff) => staff.value);

      const _exam_duration = this.props.courseList.map((course: any) => course.item.courseInfo.exam_duration);
      const exam_duration = Math.max(..._exam_duration);

      const _exam_session = this.props.courseList.map((course: any) => course.item.courseInfo.exam_session);
      const exam_session = Math.max(..._exam_session);

      let invigilator_gap_before_exam;
      let invigilator_gap_before_exams = this.props.courseList.map((i: any) => i.item.courseInfo.invigilator_gap_before_exam)
      if (hasDifferentValues(invigilator_gap_before_exams)) {
        invigilator_gap_before_exam = 0;
      } else {
        invigilator_gap_before_exam = this.props.courseList[0].item.courseInfo.invigilator_gap_before_exam;
      }

      let invigilator_gap_after_exam;
      let invigilator_gap_after_exams = this.props.courseList.map((i: any) => i.item.courseInfo.invigilator_gap_after_exam)
      if (hasDifferentValues(invigilator_gap_after_exams)) {
        invigilator_gap_after_exam = 0;
      } else {
        invigilator_gap_after_exam = this.props.courseList[0].item.courseInfo.invigilator_gap_before_exam;
      }

      let classroomCombineStatusNew = this.props.courseList.map((i: any) => i.item.courseInfo.classroom_combine_status)
      let classroomCombineStatusArray = [this.props.courseList[0].item.courseInfo.classroom_combine_status];
      const classroom_combine_status = ClassroomCombineTypes(T).find((item) => classroomCombineStatusNew.includes(item.value));

      // if (courseArray && courseArray[0] && courseArray[0].courseInfo) {
      //   let courseInfo = courseArray[0].courseInfo;

      //   fields = {
      //     courseCode: courseInfo.course_code,
      //     courseName: courseInfo.name,
      //     examDuration: courseInfo.exam_duration,
      //     invigilatorGapAfterExam: courseInfo.invigilator_gap_after_exam,
      //     invigilatorGapBeforeExam: courseInfo.invigilator_gap_before_exam,
      //     examSupervisor: courseInfo.exam_supervisor,
      //     assistantStaff: courseInfo.assistant_staff,
      //     campus: courseInfo.campus,
      //     classroomCombineStatus: courseInfo.classroom_combine_status,
      //     faculty_id: courseInfo.faculty_id
      //   };

      //   courseArray.forEach((course) => {
      //     if (course.courseInfo) {
      //       if (fields.courseName && course.courseInfo.name !== fields.courseName) {
      //         fields.courseName = undefined;
      //       }
      //       if (fields.examDuration && course.courseInfo.exam_duration !== fields.examDuration) {
      //         fields.examDuration = undefined;
      //       }
      //       if (
      //         typeof fields.invigilatorGapAfterExam !== 'number' ||
      //         course.courseInfo.invigilator_gap_after_exam !== fields.invigilatorGapAfterExam
      //       ) {
      //         fields.invigilatorGapAfterExam = undefined;
      //       }
      //       if (
      //         typeof fields.invigilatorGapBeforeExam !== 'number' ||
      //         course.courseInfo.invigilator_gap_before_exam !== fields.invigilatorGapBeforeExam
      //       ) {
      //         fields.invigilatorGapBeforeExam = undefined;
      //       }
      //       if (fields.faculty_id && course.courseInfo.faculty_id !== fields.faculty_id) {
      //         fields.faculty_id = undefined;
      //       }
      //       if (fields.examSupervisor && !equal(course.courseInfo.exam_supervisor, fields.examSupervisor)) {
      //         fields.examSupervisor = undefined;
      //       }
      //       /*if (fields.assistantStaff) {
      //         let fieldAssistantIds: Array<number> = fields.assistantStaff.map(assistant => assistant.value);
      //         let courseAssistantIds = course.courseInfo.assistant_staff_ids ? course.courseInfo.assistant_staff_ids : [];
      //         if (
      //           fieldAssistantIds.length !== courseAssistantIds.length ||
      //           !fieldAssistantIds.every(id => courseAssistantIds.indexOf(id) !== -1)
      //         ) {
      //           fields.assistantStaff = undefined;
      //         }
      //       }*/
      //       if (fields.campus && !equal(course.courseInfo.campus, fields.campus)) {
      //         fields.campus = undefined;
      //       }
      //       if (
      //         fields.classroomCombineStatus &&
      //         course.courseInfo.classroom_combine_status !== fields.classroomCombineStatus
      //       ) {
      //         fields.classroomCombineStatus = undefined;
      //       }
      //     }
      //   });
      // }

      fields = {
        course_code,
        name,
        conjugate_course_code,
        student_count_total,
        exam_duration,
        instructor_ids,
        instructors,
        assistant_staff_ids,
        assistant_staff,
        programs,
        program_ids,
        campus_id,
        campus,
        faculty_ids,
        faculties,
        campus_ids,
        campuses,
        classes,
        class_ids,
        sections,
        student_counts,
        exam_session,
        invigilator_gap_before_exam,
        invigilator_gap_after_exam,
        description,
        exam_environment: exam_environment ? exam_environment : undefined,
        exam_environment_select: exam_environment_select ? exam_environment_select : undefined,
        exam_overlap_type: exam_overlap_type ? exam_overlap_type : undefined,
        exam_overlap_type_select: exam_overlap_type_select ? exam_overlap_type_select : undefined,
        exam_type: exam_type ? exam_type : undefined,
        exam_type_select: exam_type_select ? exam_type_select : undefined,
        classroom_combine_status: classroom_combine_status ? classroom_combine_status.value : undefined
      };
    }

    return fields;
  };

  render() {

    let courseList = this.props.showCoursesOnly ? this.props.combinedDataList : this.props.courseList;
    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const CampusSelectOptions = [...ExamPeriodCourseTermLectureLocations(T), ...campusOptions];

    let fields = this.getCombinedExamsFieldsIfEqual();
    let formInitialValues = Object.assign({}, CourseFormInitialValues, fields);
    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t("gen_exam_merge")}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <div className="row">
                <div
                  className="white-container collapse show"
                  // className={
                  //   `white-container mt-4 collapse ` +
                  //   (this.props.showCoursesOnly ? `` : `show`)
                  // }
                  id="advance-search"
                  style={{ boxShadow: 'none' }}
                >
                  <Formik
                    initialValues={
                      this.props.showCoursesOnly
                        ? this.props.courseObject && this.props.courseObject.courseInfo
                          ? this.props.courseObject.courseInfo
                          : Object.assign({}, formInitialValues)
                        : Object.assign({}, formInitialValues)
                    }
                    enableReinitialize={true}
                    validationSchema={CombineExamFormValidation(T)}
                    onSubmit={(values, actions) => {
                      this.onFormSave(values, actions);
                    }}
                  >
                    {(props: FormikProps<Types.ICourseItem>) => {
                      const { values, handleChange, errors, handleBlur, handleSubmit, isSubmitting } = props;
                      Logger.data("values", values);
                      props.values.campus_id = props.values.campus && props.values.campus.value
                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-2 form-input form-group with-icon mt-3">
                              <input
                                id="course_code"
                                name="course_code"
                                value={values.course_code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={true}
                                type="text"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="course_code">{T.t('gen_code')}</label>
                              {errors && errors.course_code && props.submitCount > 0 && (
                                <div className="error">{errors && errors.course_code}</div>
                              )}
                            </div>
                            <div className="col-md-2 form-input form-group with-icon mt-3">
                              <input
                                id="name"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                disabled={true}
                                type="text"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="name">{T.t('gen_name')}</label>
                              {errors && errors.name && props.submitCount > 0 && (
                                <div className="error">{errors && errors.name}</div>
                              )}
                            </div>
                            <div className="col-md-2 form-input form-group with-icon mt-3">
                              <input
                                id="conjugate_course_code"
                                name="conjugate_course_code"
                                value={values.conjugate_course_code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="conjugate_course_code">{T.t('gen_conjugate_course_code')}</label>
                            </div>
                            <div className="col-md-2 form-input form-group with-icon mt-3">
                              <input
                                id="student_count_total"
                                name="student_count_total"
                                value={values.student_count_total}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                                disabled={true}
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="student_count_total">{T.t('gen_total_student_count')}</label>
                            </div>
                            <div className="col-md-2 form-input form-group with-icon mt-3">
                              <input
                                id="exam_duration"
                                name="exam_duration"
                                value={values.exam_duration}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="exam_duration">{T.t('gen_exam_duration')}</label>
                              {errors && errors.exam_duration && props.submitCount > 0 && (
                                <div className="error">{errors && errors.exam_duration}</div>
                              )}
                            </div>
                            <div className="col-md-2 form-input form-group with-icon mt-3">
                              <input
                                id="exam_session"
                                name="exam_session"
                                value={values.exam_session}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="exam_session">{T.t('gen_session_count')}</label>
                              {errors && errors.exam_session && props.submitCount > 0 && (
                                <div className="error">{errors && errors.exam_session}</div>
                              )}
                            </div>
                            <div className="col-md-3 form-input form-group with-icon mt-3">
                              <input
                                id="invigilator_gap_before_exam"
                                name="invigilator_gap_before_exam"
                                value={values.invigilator_gap_before_exam}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="invigilator_gap_before_exam">{T.t('gen_invigilator_gap_before_exam')}</label>
                              {errors && errors.invigilator_gap_before_exam && props.submitCount > 0 && (
                                <div className="error">{errors && errors.invigilator_gap_before_exam}</div>
                              )}
                            </div>
                            <div className="col-md-3 form-input form-group with-icon mt-3">
                              <input
                                id="invigilator_gap_after_exam"
                                name="invigilator_gap_after_exam"
                                value={values.invigilator_gap_after_exam}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="invigilator_gap_after_exam">{T.t('gen_invigilator_gap_after_exam')}</label>
                              {errors && errors.invigilator_gap_after_exam && props.submitCount > 0 && (
                                <div className="error">{errors && errors.invigilator_gap_after_exam}</div>
                              )}
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <><label id="select_campus_for_combined_exam">{T.t('gen_campus')} <i className="material-icons">info_outlined</i></label>
                                    <UncontrolledTooltip placement="bottom" target="select_campus_for_combined_exam">
                                      {T.t('gen_select_campus_for_combined_exam')}
                                    </UncontrolledTooltip></>
                                  <Select
                                    id='select_campus'
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={CampusSelectOptions}
                                    placeholder={T.t('gen_select_location')}
                                    value={props.values.campus ? props.values.campus : null}
                                    onChange={(option: any) => {
                                      props.setFieldValue('campus', option);
                                      props.setFieldValue('campus_id', option && option.value);
                                      if (option.value == ExamPeriodCourseTermLectureLocations(T)[0].value) {
                                        values.exam_environment = ExamEnvironments(T)[1].value;
                                        values.exam_environment_select = ExamEnvironments(T)[1];
                                      } else {
                                        if (values.exam_environment == ExamEnvironments(T)[1].value) {
                                          values.exam_environment = undefined;
                                          values.exam_environment_select = undefined;
                                        }
                                      }
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_exam_type')}</label>
                                  <Select
                                    id='select_exam_type'
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={ExamTypes(T)}
                                    placeholder={T.t('gen_select_exam_type')}
                                    value={
                                      props.values.exam_type != undefined
                                        ? ExamTypes(T).find(
                                          (option) => option.value === props.values.exam_type
                                        )
                                        : null
                                    }
                                    onChange={(option: any) => {
                                      props.setFieldValue('exam_type_select', option)
                                      props.setFieldValue('exam_type', option.value)
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_exam_type')}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_exam_environment')}</label>
                                  <Select
                                    id='select_exam_environment'
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={ExamEnvironments(T)}
                                    placeholder={T.t('gen_select_exam_environment')}
                                    value={
                                      props.values.exam_environment != undefined
                                        ? ExamEnvironments(T).find(
                                          (option) => option.value === props.values.exam_environment
                                        )
                                        : null
                                    }
                                    onChange={(option: any) => {
                                      props.setFieldValue('exam_environment', option.value)
                                      props.setFieldValue('exam_environment_select', option);
                                      if (option.value == ExamEnvironments(T)[1].value) {
                                        values.campus_id = ExamPeriodCourseTermLectureLocations(T)[0].value;
                                        values.campus = ExamPeriodCourseTermLectureLocations(T)[0];
                                      } else {
                                        if (values.campus_id == ExamPeriodCourseTermLectureLocations(T)[0].value) {
                                          values.campus_id = undefined;
                                          values.campus = undefined;
                                        }
                                      }
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_exam_environment')}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_exam_overlap_type')}</label>
                                  <Select
                                    id='select_exam_overlap_type'
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={ExamOverlapTypes(T)}
                                    placeholder={T.t('gen_select_exam_overlap_type')}
                                    value={
                                      props.values.exam_overlap_type != undefined
                                        ? ExamOverlapTypes(T).find(
                                          (option) => option.value === props.values.exam_overlap_type
                                        )
                                        : null
                                    }
                                    onChange={(option: any) => {
                                      props.setFieldValue('exam_overlap_type', option.value)
                                      props.setFieldValue('exam_overlap_type_select', option);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_exam_overlap_type')}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_classroom_combine_status')}</label>
                                  <Select
                                    id='select_status'
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={ClassroomCombineTypes(T)}
                                    placeholder={T.t('gen_select_status')}
                                    value={
                                      values.classroom_combine_status != undefined
                                        ? ClassroomCombineTypes(T).find(
                                          (option) => option.value === values.classroom_combine_status
                                        )
                                        : null
                                    }
                                    onChange={(option: any) =>
                                      props.setFieldValue('classroom_combine_status', option.value)
                                    }
                                    noOptionsMessage={(): string => T.t('gen_select_no_status')}
                                  />
                                </div>
                                {errors && errors.classroom_combine_status && props.submitCount > 0 && (
                                  <div className="error">{errors && errors.classroom_combine_status}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_course_assistants')}</label>
                                  <Select
                                    id='select_assistant'
                                    className="react-select"
                                    isMulti={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={false}
                                    options={
                                      this.props.selectOptions && this.props.selectOptions.instructors
                                        ? this.props.selectOptions.instructors
                                        : []
                                    }
                                    placeholder={T.t('gen_select_assistant')}
                                    value={values.assistant_staff}
                                    onChange={(
                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                    ) => {
                                      const list: Array<Types.ISelectOption> = options
                                        ? (options as Array<Types.ISelectOption>)
                                        : [];
                                      props.setFieldValue('assistant_staff', list);
                                      props.setFieldValue(
                                        'assistant_staff_ids',
                                        list.map((item) => item.value)
                                      );
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_assistan')}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 form-input form-group with-icon mt-3">
                              <textarea
                                name="description"
                                className="form-input"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                rows={2}
                                id="description"
                                value={values.description}
                                placeholder={T.t('gen_description')}
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="description" />
                              <ErrorMessage component="div" className="error" name="description" />
                            </div>
                            <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table courseformtable">
                              <thead>
                                <tr className="courseformtable-thead-tr">
                                  <th scope="col" style={{ 'width': '15%' }}>
                                    <span>{T.t('gen_campus')}</span>
                                  </th>
                                  <th scope="col" style={{ 'width': '20%' }}>
                                    <span>{T.t('gen_faculty')}</span>
                                  </th>
                                  <th scope="col" style={{ 'width': '25%' }}>
                                    <span>{T.t('gen_program')}</span>
                                  </th>
                                  <th scope="col" style={{ 'width': '5%' }}>
                                    <span>{T.t('gen_grade')}</span>
                                  </th>
                                  <th scope="col" style={{ 'width': '5%' }}>
                                    <span>{T.t('gen_section')}</span>
                                  </th>
                                  <th scope="col" style={{ 'width': '25%' }}>
                                    <span>{T.t('gen_lecture_staff')}</span>
                                  </th>
                                  <th scope="col" style={{ 'width': '5%' }}>
                                    <span>{T.t('gen_student_count')}</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="courseformtable-tbody-tr">
                                  <td className="courseformtable-td" style={{ 'width': '15%' }}>
                                    <table>
                                      <tbody>
                                        {values.campuses && values.campuses.map((item: any, index: any) =>
                                          <tr className="courseformtable-td-tr" key={index}>
                                            <td scope="row" data-label={T.t('gen_campus')}>{item.label}</td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                  <td className="courseformtable-td" style={{ 'width': '20%' }}>
                                    <table>
                                      <tbody>
                                        {values.faculties && values.faculties.map((item: any, index: any) =>
                                          <tr className="courseformtable-td-tr" key={index}>
                                            <td scope="row" data-label={T.t('gen_faculty')}>{item.label}</td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                  <td className="courseformtable-td" style={{ 'width': '25%' }}>
                                    <table>
                                      <tbody>
                                        {values.programs && values.programs.map((item: any, index: any) =>
                                          <tr className="courseformtable-td-tr" key={index}>
                                            <td scope="row" data-label={T.t('gen_program')}>{item.label}</td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                  <td className="courseformtable-td" style={{ 'width': '5%' }}>
                                    <table>
                                      <tbody>
                                        {values.classes && values.classes.map((item: any, index: any) =>
                                          <tr className="courseformtable-td-tr" key={index}>
                                            <td scope="row" data-label={T.t('gen_grade')}>{item.label}</td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                  <td className="courseformtable-td" style={{ 'width': '5%' }}>
                                    <table>
                                      <tbody>
                                        {values.sections && values.sections.map((item: any, index: any) =>
                                          <tr className="courseformtable-td-tr" key={index}>
                                            <td scope="row" data-label={T.t('gen_section')}>{item}</td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                  <td className="courseformtable-td" style={{ 'width': '25%' }}>
                                    <table>
                                      <tbody>
                                        {values.instructors && values.instructors.map((item: any, index: any) =>
                                          <tr className="courseformtable-td-tr" key={index}>
                                            <td scope="row" data-label={T.t('gen_lecture_staff')}>{item.label}</td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                  <td className="courseformtable-td" style={{ 'width': '5%' }}>
                                    <table>
                                      <tbody>
                                        {values.student_counts && values.student_counts.map((item: any, index: any) =>
                                          <tr className="courseformtable-td-tr" key={index}>
                                            <td scope="row" data-label={T.t('gen_student_count')}>{item}</td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="row mt-3">
                            <div className="col-6"></div>
                            <div className="col-6 text-right">
                              <button
                                id='button_save'
                                type="button"
                                className="btn btn-green mt-md-0 mt-2 mb-md-0 mb-2"
                                onClick={() => props.handleSubmit()}
                                disabled={isSubmitting}
                              >
                                {T.t('gen_save')}
                              </button>
                            </div>
                          </div>
                          <hr />
                          <div className="col-12">
                            <h6>{T.t("gen_merged_exam")}</h6>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
                <div className="col-12">
                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_status')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_course_no')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_code')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_conjugate_course_code')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_name')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_student_count')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_total_student_count')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_grade')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_section')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_faculty')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_campus')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_exam_duration')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_session_count')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_classroom_combine_status')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_exam_type')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_exam_environment')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_exam_overlap_type')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_connected_programs')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_instructors')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_assistants')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_description')}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {courseList &&
                        courseList.length ?
                        courseList.map((courseObject: { id: number; item: Types.IExamPeriod_CourseItem }, index: any) => {

                          let courseItem = courseObject.item.courseInfo;

                          let ClassroomCombineStatusObject: any = {};
                          let combineStatus = courseItem && courseItem.classroom_combine_status;
                          ClassroomCombineStatusObject = ClassroomCombineTypes(T).find(
                            (object) => object.value == combineStatus
                          );

                          let examType = courseItem && courseItem.exam_type;
                          let ExamTypesObject = ExamTypes(T).find(
                            (object) => object.value == examType
                          );

                          let examEnvironment = courseItem && courseItem.exam_environment;
                          let ExamEnvironmentsObject = ExamEnvironments(T).find(
                            (object) => object.value == examEnvironment
                          );

                          let examOverlapType = courseItem && courseItem.exam_overlap_type;
                          let examOverlapTypesObject = ExamOverlapTypes(T).find(
                            (object) => object.value == examOverlapType
                          );

                          return (
                            <tr
                              key={index}
                              data-title={courseItem && courseItem.name}
                            >
                              <td scope="row" data-label={T.t('gen_status')}>
                                <div className="tags ml-1 mr-4">
                                  <button
                                    id='button_status'
                                    className={
                                      `small-tag text-uppercase` +
                                      (courseItem && courseItem.status == 1 ? ` tag-green` : ` tag-red`)
                                    }
                                  >
                                    {GT.GetActiveStatus(courseItem && courseItem.status)}
                                  </button>
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_course_no')} className="text-center">
                                {courseItem && courseItem.course_id}
                              </td>
                              <td scope="row" data-label={T.t('gen_code')} className="text-center">
                                <div className="table-scrollable-td">{courseItem && courseItem.course_code ? courseItem.course_code.split(';').join(', ') : '-'}</div>
                              </td>
                              <td scope="row" data-label={T.t('gen_name')} className="text-center">
                                <div className="table-scrollable-td">{courseItem && courseItem.conjugate_course_code ? courseItem.conjugate_course_code.split(';').join(', ') : '-'}</div>
                              </td>
                              <td scope="row" data-label={T.t('gen_name')} className="text-center">
                                <div className="table-scrollable-td">{courseItem && courseItem.name ? courseItem.name.split(';').join(', ') : '-'}</div>
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.student_counts && courseItem.student_counts.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem.student_counts && courseItem.student_counts.map((i: any, index: any) => (index == courseItem!.student_counts!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.student_counts && courseItem.student_counts.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem.student_count_total}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.classes && courseItem.classes.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem.classes && courseItem.classes.map((i: any, index: any) => (index == courseItem!.classes!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.sections && courseItem.sections.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem.sections && courseItem.sections.map((i: any, index: any) => (index == courseItem!.sections!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.faculties && courseItem.faculties.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem.faculties && courseItem.faculties.map((i: any, index: any) => (index == courseItem!.faculties!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.campuses && courseItem.campuses.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem.campuses && courseItem.campuses.map((i: any, index: any) => (index == courseItem!.campuses!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td scope="row" data-label={T.t('gen_exam_duration')} className="text-center">
                                {courseItem && courseItem.exam_duration}
                              </td>
                              <td data-label={T.t('gen_session_count')} className="text-center">
                                {courseItem && courseItem.exam_session}
                              </td>
                              <td data-label={T.t('gen_classroom_combine_status')} className="text-center">
                                {ClassroomCombineStatusObject && ClassroomCombineStatusObject.label}
                              </td>
                              <td data-label={T.t('gen_exam_type')} className="text-center">
                                {ExamTypesObject && ExamTypesObject.label ? ExamTypesObject && ExamTypesObject.label : '-'}
                              </td>
                              <td data-label={T.t('gen_exam_environment')} className="text-center">
                                {ExamEnvironmentsObject && ExamEnvironmentsObject.label ? ExamEnvironmentsObject && ExamEnvironmentsObject.label : '-'}
                              </td>
                              <td data-label={T.t('gen_exam_overlap_type')} className="text-center">
                                {examOverlapTypesObject && examOverlapTypesObject.label ? examOverlapTypesObject && examOverlapTypesObject.label : '-'}
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.programs && courseItem.programs.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem.programs && courseItem.programs.map((i: any, index: any) => (index == courseItem!.programs!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.instructors && courseItem.instructors.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem.instructors && courseItem.instructors.map((i: any, index: any) => (index == courseItem!.instructors!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.assistant_staff && courseItem.assistant_staff.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem && courseItem.assistant_staff && courseItem.assistant_staff.map((i: any, index: any) => (index == courseItem!.assistant_staff!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td data-label={T.t('gen_description')} className="text-center">
                                {courseItem && courseItem.description ? courseItem.description : '-'}
                              </td>
                            </tr>
                          );
                        }) : null}
                    </tbody>
                  </table>
                  <div className="row-options justify-content-end">
                    <div
                      className="page-sorting d-flex align-items-center justify-content-center"
                      style={{ marginTop: '5px' }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.ICombineModalProps
): Types.ICombineModalProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.ICombineModalProps = Object.assign({}, ownProps, {
    selectOptions: store.state.select_options && store.state.select_options.coursePage
  });
  return newProps;
};

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CombineExamsModal);

export default container;
